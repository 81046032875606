<template>
  <form ref="searchForm" @submit.prevent="goToSearchPage">
    <div
      ref="inputWrap"
      class="relative flex border"
      :class="errorMessage ? 'border-red-50' : 'border-gray-30'"
    >
      <button
        type="submit"
        :aria-label="$t('search')"
        class="inline-flex h-7.5 w-8.5 shrink-0 items-center justify-center border-r bg-orange-30"
        :class="errorMessage ? 'border-red-50' : 'border-gray-30'"
      >
        <UiIcon name="search" class="size-4" />
      </button>
      <input
        ref="inputEl"
        v-model.trim="search"
        name="search"
        :placeholder="$t('search')"
        autocomplete="off"
        class="h-7.5 w-full pl-2.5 pr-8 text-base text-gray-60 placeholder-gray-60 focus:outline-none"
        @keydown.esc="$emit('close')"
      />
      <button
        v-if="search"
        type="button"
        class="absolute right-2.5 top-1/2 inline-flex size-4 -translate-y-1/2 items-center justify-center"
        :class="errorMessage && 'text-error'"
        :aria-label="$t('clearInput')"
        @click="search = ''"
      >
        <UiIcon name="close" class="size-4" />
      </button>
    </div>
    <SharedFieldHint v-if="errorMessage" :message="errorMessage" error />
    <div
      v-if="search && !errorMessage"
      class="absolute left-0 right-0 top-full z-10 pt-px"
    >
      <div class="flex w-full bg-white pb-10 pt-7.5">
        <CommonAppHeaderSearchResult
          v-if="search"
          :count="count"
          :style="{ maxWidth: `${width}px`, marginLeft: `${left}px` }"
          class="w-full"
          :search="search"
          :list-data="listData"
          :loading="loading"
          @result-click="$emit('close')"
        />
      </div>
    </div>
    <teleport to="body">
      <div class="fixed inset-0 z-10 h-full w-full"></div>
    </teleport>
  </form>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";

const inputEl = ref<HTMLInputElement>();
const inputWrap = ref<HTMLElement>();
const searchForm = ref(null);
const emit = defineEmits(["close", "goToSearchPage"]);

const { loading, listData, search, count, errorMessage, goToSearchPage } =
  useSearch();

const { width, left } = useElementBounding(inputWrap);

onClickOutside(searchForm, () => {
  setTimeout(() => {
    emit("close");
  }, 0);
});

watchEffect(() => {
  if (inputEl.value) {
    inputEl.value.focus();
  }
});
</script>
